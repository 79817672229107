import style from "../../css/RegisterPartnerShipView.module.scss";

import HouseIcon from "../../resources/icon/house.svg"
import OfficeIcon from "../../resources/icon/office.svg"
import {useNavigate} from "react-router-dom";
import useLocalStorage from "../../modules/useLocalStorage";
import NavigationBar from "../../components/NavigationBar";


function RegisterPartnerShipView() {

    const navigate = useNavigate();

    const [homePartner, setHomePartner] = useLocalStorage("homePartner")
    const [companyPartner, setCompanyPartner] = useLocalStorage("companyPartner")

    return (
        <div className={"WhiteBg"}>
            <NavigationBar title={"제휴사 등록"} backButton={true}/>
            <div className={style.Grid}>
                {homePartner &&
                    <div className={style.Item}>
                        <div className={style.InfoBox}>
                            <img src={HouseIcon} alt={"icon"}/>
                            <div className={style.textWrapper}>
                                <div className={style.title}>{homePartner.title}</div>
                                <div className={`${style.subtitle} ${style.detail}`}>{homePartner.other.detailAddress}</div>
                            </div>
                        </div>
                        <hr/>
                        <div className={style.approvalStatus}>
                            <div className={style.status}>승인대기중</div>
                            <div className={style.contact}>문의하기 {homePartner.contact}</div>
                        </div>
                        <div className={style.Delete} onClick={() => {
                            setHomePartner(null)
                        }}>해지
                        </div>
                    </div>
                }
                {!homePartner &&
                    <div className={style.Item} onClick={() => {
                        navigate("/partnerships/home")
                    }}>
                        <div className={style.InfoBox}>
                            <img src={HouseIcon} alt={"icon"}/>
                            <div className={style.textWrapper}>
                                <div className={style.title}>집</div>
                                <div className={`${style.subtitle}`}>등록하려면 클릭해주세요.</div>
                            </div>
                        </div>
                    </div>
                }
                {companyPartner &&
                <div className={style.Item}>
                    <div className={style.InfoBox}>
                        <img src={OfficeIcon} alt={"icon"}/>
                        <div className={style.textWrapper}>
                            <div className={style.title}>{companyPartner.title}</div>
                            <div className={`${style.subtitle} ${style.detail}`}>{companyPartner.other.detailAddress}</div>
                        </div>
                    </div>
                    <div className={style.Delete} onClick={() => {
                        setCompanyPartner(null)
                    }}>해지
                    </div>
                </div>
            }
            {!companyPartner &&
                <div className={style.Item} onClick={() => {
                    navigate("/partnerships/office")
                }}>
                    <div className={style.InfoBox}>
                        <img src={OfficeIcon} alt={"icon"}/>
                        <div className={style.textWrapper}>
                            <div className={style.title}>회사</div>
                            <div className={style.subtitle}>등록하려면 클릭해주세요.</div>
                        </div>
                    </div>
                </div>
            }
            </div>
        </div>
    )
}

export default RegisterPartnerShipView