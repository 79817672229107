import style from "../../css/module/ReservationView.module.scss";
import moment from "moment"
import 'moment/locale/ko'

function AdminMessage(props) {

    const {text, time} = props

    return (
        <div className={`${style.Message} ${style.UserMessage}`}>
            <div className={style.Content} dangerouslySetInnerHTML={{__html: text}}></div>
            <div className={style.Time}>{moment(time).format("a hh:mm")}</div>
        </div>
    )
}

export default AdminMessage