import {Product} from "./types/Product"

export const ProductFetch = () => {

    const washProduct = async (): Promise<Product[]> => {
        return new Promise((resolve, reject) => {
            resolve([
                {
                    id: 1,
                    type: "primary",
                    title: "기본",
                    content: "내외부 세차",
                    price: 30000,
                } as Product,
                {
                    id: 2,
                    type: "primary",
                    title: "플러스",
                    content: "내외부 세차",
                    price: 40000,
                } as Product,
                {
                    id: 3,
                    type: "primary",
                    title: "플러스+A",
                    content: "내외부 세차",
                    price: 50000,
                } as Product,
            ])
        })
    }

    const additionalProduct = async (): Promise<Product[]> => {
        return new Promise((resolve, reject) => {
            resolve([
                {
                    id: 4,
                    type: "additional",
                    title: "세차",
                    content: "",
                    price: 30000,
                } as Product,
                {
                    id: 5,
                    type: "additional",
                    title: "세차+광택",
                    content: "",
                    price: 40000,
                } as Product,
                {
                    id: 6,
                    type: "additional",
                    title: "A코스",
                    content: "",
                    price: 50000,
                } as Product,
                {
                    id: 7,
                    type: "additional",
                    title: "B코스",
                    content: "",
                    price: 55000,
                } as Product,
            ])
        })
    }

    return {washProduct, additionalProduct}
}