import style from './BarButton.module.scss';

function BarButton(properties) {
    const {icon, text, backgroundColor, textColor, click, disabled, className, height} = properties

    const defaultProps = {
        className: `${style.Button} ${className ?? ''}`,
        disabled: disabled,
        onClick: click,
        style: {
            backgroundColor: backgroundColor,
            color: textColor,
            height: height
        }
    }

    return (
        <button {...defaultProps}>
            {icon && <img src={icon} alt={"icon"}/>}
            <span>{text}</span>
        </button>
    )
}

export default BarButton;