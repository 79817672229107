import React from "react"

import style from "../css/module/BottomDrawer.module.scss"
import {Link} from "react-router-dom"
import {Car} from "../model/types/Car"

const BottomDrawer = (data: {cars: Car[]}) => {

    const {cars} = data

    const render = (cars: Car[]) => {
        if (Array.isArray(cars) && cars.length > 0) {
            return (
                <div className={style.cars}>
                    <div className={style.left}>
                        <div className={style.title}>
                            차량 정보
                        </div>

                        <div className={`${style.title} mt-3`}>
                            {cars[0].plateNumber}
                        </div>
                        <div className={style.subtitle}>
                            {cars[0].model}
                        </div>
                    </div>
                    <div className={style.right}>
                        <img src={cars[0].image} alt={""} />
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className={style.cars}>
                    <div className={style.left}>
                        <div className={style.title}>
                            등록된 주소가 없습니다.
                        </div>
                        <div className={style.subtitle}>
                            주소를 등록해주세요.
                        </div>
                    </div>
                    <div className={style.right}>
                        <Link to={"/places/add"} className={style.button}>주소 등록하기</Link>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className={style.BottomDrawer}>
            <div className={style.DrawerHandle}>
                <span className={style.bar}></span>
            </div>
            {render(cars)}
        </div>
    )
}

export default BottomDrawer