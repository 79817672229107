import {Post} from "./types"
import dayjs from "dayjs"

const genPost = (type: string, index: number, size: number) => {
    let data = [] as Post[]
    if (index > 100) {
        return null
    }
    let prefix
    if (type === "NOTICE") {
        prefix = "공지사항"
    }
    else {
        prefix = "자주 묻는 질문"
    }
    for (let i = index; i < (index + size); i++) {
        data.push({
            id: i + 1,
            type,
            title: `${prefix} 더미 ${i + 1}`,
            content: `What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s`,
            created: dayjs("2023-04-13 19:18").toDate(),
            modified: dayjs("2023-04-13 19:18").toDate(),
        })
    }

    return data
}

export const BoardFetch = () => {

    const notice = async (page: number, size: number) => {
        const param = {page, size}

        return Promise.resolve(
            genPost("NOTICE", page * size, size)
        )
    }

    const faq = async (page: number, size: number) => {
        const param = {page, size}

        return Promise.resolve(
            genPost("FAQ", page * size, size)
        )
    }

    return {notice, faq}
}
