import kakaoApiInstance from "../modules/networkKakao";

export const AddressAPI = () => {

    const coordToAddress = async (lat: number, lng: number) => {
        return await kakaoApiInstance.get("https://dapi.kakao.com/v2/local/geo/coord2address.json", {params: {x: lng, y: lat}})
            .then((response) => {
                console.info(response.data.documents)
                return response.data.documents
            })
    }

    return {coordToAddress}
}