import ChevronLeft from "../../resources/icon/chevron-left.svg";
import {useNavigate, useSearchParams} from "react-router-dom"

import style from "./BackButton.module.scss"

function BackButton() {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams()

    return (
        <div className={style.BackButton} onClick={() => {
            const mode = searchParams.get("mode")
            if (mode === "washing") {
                navigate(-1)
            }
            else if (mode === "cleaning") {
                navigate(-1)
            }
            else {
                navigate(-1)
            }
        }}>
            <img src={ChevronLeft} alt={"뒤로 가기"}/>
        </div>
    )
}

export default BackButton;