import DemoCar from "../resources/demo/car.png"
import moment from "moment"
import {History} from "./types"

const data = [
    {
        id: 1,
        type: "WASH",
        price: 70000,
        date: moment("2023-11-16 16:02", "YYYY-MM-DD HH:mm").format(),
        address: {
            lat: 37.5436419,
            lng: 126.9860114,
            parcel: "서울특별시 용산구 용산2가동 30-9",
            road: "서울특별시 용산구 신흥로11나길 10",
        },
        car: {
            id: 1,
            color: 3,
            plateNumber: "123가5678",
            model: "Audi A6",
            image: DemoCar,
        },
    } as History,
    {
        id: 2,
        type: "CLEAN",
        price: 70000,
        date: moment("2023-11-16 15:02", "YYYY-MM-DD HH:mm").format(),
        address: {
            lat: 37.5436419,
            lng: 126.9860114,
            parcel: "서울특별시 용산구 용산2가동 30-9",
            road: "서울특별시 용산구 신흥로11나길 10",
        },
    } as History,
]

export const HistoryFetch = () => {


    const histories = async (filter?: string): Promise<History[]> => {
        return new Promise((resolve, reject) => {
            if (filter !== undefined && filter.length > 0) {
                resolve(data.filter((content) => {
                    return content.type === filter
                }))
            }
            else {
                resolve(data)
            }
        })
    }

    const history = async (id: number): Promise<History> => {
        return new Promise((resolve, reject) => {
            console.info(`getting history: ${id}`)
            for (let i = 0; i < data.length; i++) {
                console.info(data[i])
                console.info(id)
                console.info(data[i].id === id)
                if (data[i].id == id) {
                    resolve(data[i])
                    break
                }
            }
        })
    }

    return {histories, history}
}
