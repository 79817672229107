import Axios from "axios";
import qs from "qs";

const kakaoApiInstance = Axios.create({
    timeout: 50000,
    headers: {
        "Content-Type": "application/json",
        "Authorization": "KakaoAK 408973df474c8cad6eb6b1771c0ffa3d"
    }
})

kakaoApiInstance.defaults.paramsSerializer = params => {
    return qs.stringify(params);
}

kakaoApiInstance.interceptors.request.use(
    (config) => {
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);
kakaoApiInstance.interceptors.response.use(
    (config) => {
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

export default kakaoApiInstance;