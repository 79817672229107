import {useState} from "react"

const useLocalStorage = (key) => {

    const initialValue = localStorage.getItem(key)

    const [persistedValue, setPersistedValue] = useState(
        initialValue ? JSON.parse(initialValue) : initialValue
    )

    const setValue = newValue => {
        setPersistedValue(newValue)
        localStorage.setItem(key, JSON.stringify(newValue))
    }

    const removeLocalStorage = key => {
        localStorage.removeItem(key)
    }

    return [persistedValue, setValue, removeLocalStorage]
}

export default useLocalStorage
