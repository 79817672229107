import DemoCar from "../resources/demo/car.png"
import {Car} from "./types/Car"

export const CarFetch = () => {

    let data: Car[] = [
        {
            id: 1,
            color: 3,
            plateNumber: "123가5678",
            model: "Audi A6",
            image: DemoCar,
        } as Car
    ]

    const car = async (carId: number): Promise<Car> => {
        for (let index in data) {
            const car = data[index]
            if (car.id === carId) {
                return Promise.resolve(car)
            }
        }
        return Promise.reject("Not found a car")
    }

    const deleteCar = async (carId: number): Promise<boolean> => {
        let newList = data.filter(item => {
            return item.id !== carId
        })
        if (data.length !== newList.length) {
            data = newList
            return Promise.resolve(true)
        }
        return Promise.resolve(false)
    }

    const cars = async (userId: number): Promise<Car[]> => {
        return Promise.resolve(data)
    }

    return {cars, car, deleteCar}
}
