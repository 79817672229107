import React, {Suspense, useEffect} from "react"

import "./css/App.scss"

import {useLocation, useRoutes, useSearchParams} from "react-router-dom"
import routes from "./modules/router"

import {DeviceFrameset} from "react-device-frameset"
import "react-device-frameset/styles/marvel-devices.min.css"
import classnames from "classnames"

import ReactGA from "react-ga"
import {useSessionStorage} from "./modules/hooks/useSessionStorage"
import {UserProfile} from "./model/types/UserProfile"
import Keys from "./keys"

// const TRACKING_ID = "G-QSQ0TBLGJE"
// ReactGA.initialize(TRACKING_ID)

const Root = () => {

    const [device, setDevice] = useSessionStorage<string>(Keys.Device)
    const [user] = useSessionStorage<UserProfile>(Keys.User)
    let [searchParams] = useSearchParams()
    const route = useRoutes(routes)
    const location = useLocation()

    useEffect(() => {
        const {userAgent} = window.navigator
        if (userAgent.match("APP_ANDROID") || userAgent.includes("android-webview")) {
            setDevice("android")
        }
        else if (userAgent.match("APP_IOS") || userAgent.includes("ios-webview")) {
            setDevice("ios")
        }
        else {
            setDevice("")
        }

        // ReactGA.pageview(window.location.pathname + window.location.search)

    }, [searchParams, setDevice])

    const isLogin = () => {
        return (user !== null && user !== undefined) && location.pathname === "/"
    }

    const layout = (<>
        <div className={"App"}>
            <div className={classnames(`Container`, {Login: isLogin()})}>
                {route}
            </div>
        </div>
    </>)

    return (
        <Suspense fallback="Loading...">
            {(process.env.REACT_APP_DEV && device?.length < 1) &&
                // <DeviceFrameset device="iPhone X" color="black">
                //     {layout}
                // </DeviceFrameset>
                layout
            } {!process.env.REACT_APP_DEV &&
            layout
        }
        </Suspense>
    )
}

export default Root
