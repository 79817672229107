import style from "../../css/module/ReservationView.module.scss"

import NavigationBar from "../../components/NavigationBar"
import {useNavigate, useSearchParams} from "react-router-dom"


function CleanRequestView() {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams()

    return (
        <div className={"WhiteBg"}>
            <NavigationBar title={"상담원 연결"} backButton={true}/>
            <div className={style.ChatWrapper}>
                <div className={style.Cars}>
                </div>
                <div className={style.PriceTable}>

                </div>
            </div>
        </div>
    )
}

export default CleanRequestView