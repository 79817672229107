import BarButton from "../../components/buttons/BarButton";
import NavigationBar from "../../components/NavigationBar";
import BottomActionButton from "../../components/buttons/BottomActionButton";


function CarAddView() {

    const validate = () => {
        alert("필수 사항을 모두 입력해주세요.")
    }

    return (
        <>
            <NavigationBar title={"차량 추가 하기"} backButton={true}/>
            <div className={"WhiteBg"}>
                <div className={"Form"}>
                </div>
            </div>
            <BottomActionButton title={"추가"} backgroundColor={"#375126"} textColor={"#FFF"} click={validate}/>
        </>
    )
}

export default CarAddView