import {Partner} from "./types/Partner"

export const PartnerFetch = () => {

    const housePartners = async () => {

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve([
                    {
                        id: 1,
                        title: '반포자이아파트'
                    } as Partner,
                    {
                        id: 2,
                        title: '청담자이아파트'
                    } as Partner,
                    {
                        id: 3,
                        title: '서초자이아파트'
                    } as Partner
                ])
            }, 100)
        })
    }

    const companyPartners = async () => {

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve([
                    {
                        id: 1,
                        title: '네이버'
                    } as Partner,
                    {
                        id: 2,
                        title: '카카오'
                    } as Partner,
                    {
                        id: 3,
                        title: '현대자동차'
                    } as Partner,
                    {
                        id: 4,
                        title: '기아자동차'
                    } as Partner
                ])
            }, 100)
        })
    }

    return {housePartners, companyPartners}
}