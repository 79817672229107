import React from "react";
import style from "../css/Dropdown.module.scss";

export default function Dropdown(props) {
    const {
        label,
        labelRequired,
        optionList,
        state,
        setState,
        placeholder,
        disabled
    } = props

    const handleChange = (e) => {
        e.preventDefault()
        //console.log(e.target.value)
        setState(parseInt(e.target.value) || e.target.value)
    }

    return (<>
            <div className={style.InputGroup}>
                {label && <label className={`${labelRequired ? style.required : ""}`}>{label}</label>}
                <div className={`${style.dropdown} ${disabled && style.disabled}`}>
                    <select className={`fs-regular ${state === "" ? style.placeholder : ""}`} value={state} onChange={handleChange}>
                        {placeholder && <option value={""} disabled={true}>{placeholder}</option>}
                        {optionList.map((option, i) => {
                            return (<option value={option.id} key={i}>{option?.title}</option>)
                        })}
                    </select>
                    <span className={`material-icons-round ${style.arrow} ${state === "" && style.placeholder}`}>expand_more</span>
                </div>
            </div>
        </>
    )
}
