import style from "../css/module/SearchView.module.scss"
import mainStyle from "../css/module/Main.module.scss"
import SearchIcon from "../resources/icon/search.svg"
import React, {useRef, useState} from "react"
import BackButton from "../components/buttons/BackButton"

const SearchView = (data: {referer?: string}) => {

    const {referer} = data

    const [searchInput] = useState<string>()
    const searchRef = useRef<HTMLInputElement>(null)

    return (
        <>
            <div className={mainStyle.WhiteBg}>
                <div className={style.Navi}>
                    <BackButton />
                    <div className={style.SearchBar}>
                        <img src={SearchIcon} alt={"search icon"} />
                        <input className={style.SearchInput} value={searchInput} ref={searchRef} onFocus={() => {
                        }} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchView