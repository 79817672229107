import style from "./HistoryDetailView.module.scss"
import NavigationBar from "../../components/NavigationBar"
import {useEffect, useState} from "react"
import {Link, useNavigate, useParams} from "react-router-dom"
import {HistoryFetch} from "../../model/HistoryFetch"
import CleanHistoryDetailView from "./CleanHistoryDetailView"
import WashHistoryDetailView from "./WashHistoryDetailView"

const HistoryDetailView = () => {

    const navigate = useNavigate()
    const params = useParams()
    const {history} = HistoryFetch()
    const [type, setType] = useState<string>()

    const id = params.id as unknown as number | null

    useEffect(() => {
        setTimeout(() => {
            if (!id) {
                alert("잘못된 경로 입니다.")
                navigate("/app")
            }
            else {
                history(id).then((data) => {
                    console.info(data)
                    setType(data.type)
                })
            }
        }, 50)
    }, [])

    return (
        <div className={"WhiteBg"}>
            <NavigationBar title={""} backButton={true} />
            <div className={style.PageWrapper}>
                {type && (
                    type === "WASH" ? <WashHistoryDetailView contentId={id} /> :
                        (type === "CLEAN" ? <CleanHistoryDetailView contentId={id} /> : "")
                )}
            </div>
            <div className={style.FixToBottom}>
                <Link to={"#"}>채팅 보기</Link>
                <Link to={"#"}>문의하기</Link>
            </div>
        </div>
    )
}

export default HistoryDetailView