import BackButton from "../components/buttons/BackButton";
import BarButton from "../components/buttons/BarButton";
import NavigationBar from "../components/NavigationBar";
import BottomActionButton from "../components/buttons/BottomActionButton";


function PaymentView() {

    const validate = () => {
        alert("필수 사항을 모두 입력해주세요.")
    }

    return (
        <div className={"WhiteBg"}>
            <NavigationBar title={"결제 수단 관리"} backButton={true}/>
            <div className={"Form"}>

            </div>
            <BottomActionButton title={"추가"} backgroundColor={"#375126"} textColor={"#FFF"} click={validate}/>
        </div>
    )
}

export default PaymentView