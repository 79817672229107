import style from "./HistoryDetailView.module.scss"
import {useEffect, useState} from "react"
import moment from "moment"
import {thousandSeparator} from "../../utils/NumberUtils"
import {Map, useKakaoLoader} from "react-kakao-maps-sdk"
import CarColorChip from "../../components/CarColorChip"
import {useNavigate, useSearchParams} from "react-router-dom"
import {HistoryFetch} from "../../model/HistoryFetch"
import {Geo, History} from "../../model/types"

const WashHistoryDetailView = (data: {contentId: number}) => {

    const navigate = useNavigate()

    const [searchParams] = useSearchParams()

    const [content, setContent] = useState<History>()

    const {history} = HistoryFetch()

    const [state, setState] = useState<{level: number, center: Geo}>()

    const [price] = useState(70000)

    useKakaoLoader({
        appkey: "9ef65d72b96c6881edf8066e09089d38", // 발급 받은 APPKEY
    })

    const {contentId} = data

    useEffect(() => {
        setTimeout(() => {
            if (!contentId) {
                alert("잘못된 경로 입니다.")
                navigate("/app")
            }
            else {
                history(contentId).then(data => {
                    setContent(data)
                })
            }
        }, 50)
    }, [navigate, setContent, history])

    const validate = () => {
        alert("필수 사항을 모두 입력해주세요.")
    }

    const render = (content: History) => {
        return (
            <>
                <div className={style.Meta}>
                    <div className={style.MetaTitle}>
                        세차
                    </div>
                    <div className={style.Time}>{moment(content.date, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD a hh:mm")}</div>
                    <Map id="map" className={style.Map} center={{lat: content.address?.lat ?? 37.5436419, lng: content.address?.lng ?? 126.9860114}} level={2} onCenterChanged={(map) => setState({
                        level: map.getLevel(),
                        center: {
                            lat: map.getCenter().getLat(),
                            lng: map.getCenter().getLng(),
                        },
                    })} />
                </div>
                <div className={style.Meta}>
                    <div className={style.MetaTitle}>
                        상세내역
                    </div>
                    <div className={style.Cars}>
                        <div>
                            <img className={style.Thumbnail} src={content.car.image} alt={""} />
                        </div>
                        <div className={style.CarInfo}>
                            <div className={style.PlateNumber}>{content.car.plateNumber}</div>
                            <div className={style.ModelInfo}>
                                <CarColorChip colorCode={content.car.color} size={18} isCircle={true} /> {content.car.model}
                            </div>
                        </div>
                    </div>
                    <div className={style.CarProduct}>
                        <div className={style.CarProductInfo}>
                            이용 상품
                            <div>거품+실내세차</div>
                        </div>
                        <div className={style.CarProductInfo}>
                            결제 금액
                            <div>{thousandSeparator(price)}원</div>
                        </div>
                        <div className={style.CarProductInfo}>
                            결제 수단
                            <div>현장결제</div>
                        </div>
                        <div className={style.CarProductInfo}>
                            할인 옵션
                            <div>청담 자이 입주민 <span className={style.point}>-7%</span></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {content && (
                render(content)
            )}
        </>
    )
}

export default WashHistoryDetailView