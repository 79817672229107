import NavigationBar from "../../components/NavigationBar"
import {BoardFetch} from "../../model/BoardFetch"
import {useEffect, useState} from "react"
import {Post} from "../../model/types"
import style from "./PostView.module.scss"
import dayjs from "dayjs"


function FaqView() {

    const {faq: getFaq} = BoardFetch()

    const [posts, setPosts] = useState<Post[]>([])

    useEffect(() => {
        setTimeout(() => {
            getFaq(0, 30).then(data => {
                setPosts(data)
            })
        }, 50)
    }, [])

    return (
        <div className={style.WhiteBg}>
            <NavigationBar title={"FAQ"} backButton={true} />
            <div className={style.Contents}>
                {posts && posts.map((post) => {
                    return (
                        <>
                            <div className={style.Post}>
                                <div className={style.PostTitle}>
                                    {post.title}
                                    <span className={style.PostDate}>{dayjs(post.created).format("YYYY/MM/DD")}</span>
                                </div>
                                <div className={style.PostBody}>
                                    {post.content}
                                </div>
                            </div>
                        </>
                    )
                })}
            </div>
        </div>
    )
}

export default FaqView
