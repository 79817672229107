import style from "../../css/module/ReservationView.module.scss"

import NavigationBar from "../../components/NavigationBar"
import {useCallback, useEffect, useRef, useState} from "react"
import {CarFetch} from "../../model/CarFetch"
import {Link, useNavigate, useSearchParams} from "react-router-dom"
import CarColorChip from "../../components/CarColorChip"
import DateLabel from "../../components/DateLabel"
import TopIcon from "../../resources/icon/chevron-top.svg"
import CheckIcon from "../../resources/icon/check.svg"
import ReservationStatusBox from "./ReservationStatusBox"
import AdminMessage from "./AdminMessage"
import moment from "moment"
import UserMessage from "./UserMessage"
import BarButton from "../../components/buttons/BarButton"
import {thousandSeparator} from "../../utils/NumberUtils"
import {ProductFetch} from "../../model/ProductFetch"
import {Car, Message, Product, UserProfile} from "../../model/types"
import dayjs from "dayjs"
import {useSessionStorage} from "../../modules/hooks/useSessionStorage"
import Keys from "../../keys"

function WashRequestView() {

    const navigate = useNavigate()

    const [user] = useSessionStorage<UserProfile>(Keys.User)

    const [searchParams] = useSearchParams()

    const [objectId] = useState(searchParams.get("car") ?? null)

    const [car, setCar] = useState<Car | null>(null)
    const [cars, setCars] = useState<Car[]>([])
    const {cars: carFetcher, car: getCar} = CarFetch()

    const [products, setProducts] = useState<Product[]>([])
    const [additionalProducts, setAdditionalProducts] = useState<Product[]>([])
    const {washProduct: productFetcher, additionalProduct: additionalFetcher} = ProductFetch()

    const [isConnected, setConnected] = useState(false)
    const [selectedCourse, setSelectedCourse] = useState<Product | null>(null)

    const [isBooked, setBooked] = useState(false)

    const [isSendAvailable, setSendAvailable] = useState(false)

    const inputRef = useRef<HTMLTextAreaElement>(null)

    const [messages, setMessages] = useState<Message[]>([])

    const pushMessage = useCallback(({type, message, name}: {type: string, message: string, name: string}) => {
        const list = messages

        list.push({
            id: messages.length + 1,
            type: type,
            name: name,
            message: message,
            time: moment().toDate(),
        })

        console.log(`after - ${JSON.stringify(list)}`)

        setMessages(list)
    }, [])

    useEffect(() => {
        console.log("Loading product")

        Promise.all([
            productFetcher(),
            additionalFetcher(),
        ])
            .then(([products, additionalProducts]) => {
                if (!(products === null || products === undefined)) {
                    setProducts(products)
                }
                if (!(additionalProducts === null || products === undefined)) {
                    setAdditionalProducts(additionalProducts)
                }
            })

        if (!isConnected) {
            setTimeout(() => {
                pushMessage({type: "admin-0", message: "안녕하세요, 고객님<br/> 청소 옵션을 선택해주세요.", name: ""})
                setConnected(true)
            }, 1200)
        }
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (!objectId) {
                alert("잘못된 경로 입니다.")
                navigate("/app")
                return
            }
            const id = parseInt(objectId)
            getCar(id).then(data => {
                if (!(data === null || data === undefined)) {
                    setCar(data)
                }
            })
        }, 50)
    }, [objectId, navigate, getCar])

    useEffect(() => {
        setTimeout(() => {
            carFetcher(user.id).then(data => {

                if (data.length === 0) {
                    alert("잘못된 경로 입니다.")
                    navigate("/app")
                    return
                }

                setCars(data)
            })
        }, 50)
    }, [carFetcher, cars, setCars, navigate])

    const [isOpenCarProduct, setOpenCarProduct] = useState(false)

    const toggleCarProduct = () => {
        setOpenCarProduct(!isOpenCarProduct)
    }

    const sendMessage = () => {
        if (inputRef === null || inputRef.current === null) {
            return
        }

        const value = inputRef.current.value
        inputRef.current.value = ""
        pushMessage({type: "user", message: value, name: ""})

        let timeLocation = value.search(/[시분]/)

        if (timeLocation > 0) {
            pushMessage({type: "admin", message: "해당시간에 맞춰 예약 했습니다. 결제완료 해주시면 다음 스텝으로 진행하겠습니다.", name: "김민재 대리"})
            setBooked(true)
        }
    }

    return (
        <div className={style.WhiteBg}>
            <NavigationBar title={"상담원 연결"} backButton={true} />
            <div className={style.CarWrapper}>
                <div className={style.Cars}>
                    {car &&
                        <>
                            <img className={style.Thumbnail} src={car.image} alt={""} />
                            <div className={style.CarInfo}>
                                <div className={style.PlateNumber}>{car.plateNumber}</div>
                                <div className={style.ModelInfo}>
                                    <CarColorChip colorCode={car.color} size={18} isCircle={true} /> {car.model}
                                </div>
                            </div>
                        </>
                    } {selectedCourse &&
                    <img className={`${style.ToggleButton} ${isOpenCarProduct ? style.Open : ""}`} src={CheckIcon} alt={"open"} onClick={() => {
                        toggleCarProduct()
                    }} />
                }
                </div>
                {selectedCourse &&
                    <div className={`${style.CarProduct} ${isOpenCarProduct ? style.Open : ""}`}>
                        <div className={style.CarProductInfo}>
                            이용 상품
                            <div>{selectedCourse.title}</div>
                        </div>
                        <div className={style.CarProductInfo}>
                            결제 금액
                            <div>{thousandSeparator(selectedCourse.price)}원</div>
                        </div>
                        <div className={style.CarProductInfo}>
                            진행상태
                            <div>{isBooked ? "결제 대기 중" : "예약 진행 중"}</div>
                        </div>
                    </div>
                }
            </div>
            <div className={style.ChatWrapper}>
                {products &&
                    <>
                        <div className={style.Date}>
                            <DateLabel date={dayjs()} />
                        </div>
                        <div className={style.PriceTable}>
                            <table>
                                <tbody>
                                <tr>
                                    <th>옵션</th>
                                    <th>내용</th>
                                    <th>가격</th>
                                </tr>
                                {products.map((product) => {
                                    return (
                                        <tr data-product-id={product.id} key={product.id}>
                                            <th>{product.title}</th>
                                            <td>{product.content}</td>
                                            <td>{thousandSeparator(product.price)}원</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        <ReservationStatusBox key={0} title={"예약을 진행 중입니다."} message={"상담원이 배정되면 채팅을 통해 알려드리겠습니다."} status={0} />
                    </>
                }
                <div className={style.Chats}>
                    {isConnected &&
                        <>
                            <AdminMessage key={`msg-${messages[0].id}`} name={messages[0].name} text={messages[0].message} time={messages[0].time} />
                            <div className={`${style.Message} ${style.AdminMessage}`}>
                                <div className={style.WorkList}>
                                    {additionalProducts && additionalProducts.map(p => {
                                        return (
                                            <span className={style.WorkTag} key={`pa-${p.id}`} onClick={(e) => {
                                                if (selectedCourse !== null) {
                                                    return
                                                }
                                                setSelectedCourse(p)
                                                setSendAvailable(true)
                                                pushMessage({
                                                    type: "user", message: `${p.title} 코스로 안내해주세요`, name: "",
                                                })
                                                setTimeout(() => {
                                                    e.preventDefault()
                                                    pushMessage({
                                                        type: "admin", message: `안녕하세요, 고객님 ${p.title}로 안내 드리겠습니다. 세차 시간과 날짜를 말씀해주세요.`, name: "김민재 대리",
                                                    })
                                                }, 750)
                                            }}>{p.title}</span>
                                        )
                                    })}
                                </div>
                            </div>
                        </>
                    } {selectedCourse &&
                    <>
                        {messages && messages.map(message => {
                            if (message.type === "user") {
                                return (<UserMessage key={`msg-${message.id}`} text={message.message} time={message.time} />)
                            }
                            else {
                                return (<AdminMessage key={`msg-${message.id}`} name={message.name} text={message.message} time={message.time} />)
                            }
                        })
                        }
                    </>
                }
                </div>

                {isBooked &&
                    <ReservationStatusBox key={1} title={"결제 대기중입니다."} message={"결제를 진행해주시면 세차 시간이 확정됩니다."} status={1} actions={[
                        <BarButton key={1} text={`${thousandSeparator(30000)}원 결제하기`} disabled={false} backgroundColor={"#1F2E27"} textColor={"#FFF"} height={36} click={() => {
                            navigate("/app/payments?revn=1")
                        }} />,
                    ]} />
                }
            </div>
            <div className={style.MessageInput}>
                <div className={style.InputBox}>
                    <textarea ref={inputRef} disabled={!isSendAvailable} rows={1}></textarea>
                    <>
                        <Link to={""} className={style.SendButton} onClick={(e) => {
                            e.preventDefault()
                            sendMessage()
                        }}> <img src={TopIcon} alt={""} /> </Link>
                    </>
                </div>
            </div>
        </div>
    )
}

export default WashRequestView
