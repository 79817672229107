import NavigationBar from "../../components/NavigationBar"
import BottomActionButton from "../../components/buttons/BottomActionButton"

function AddressView() {

    const addAddress = () => {

    }

    return (
        <div className={"WhiteBg"}>
            <NavigationBar title={"주소 관리"} backButton={true} />
            <BottomActionButton title={"추가"} backgroundColor={"#375126"} textColor={"#FFF"} click={addAddress} />
        </div>
    )
}

export default AddressView