import LoginView from "../views/login/Login"
import MainView from "../views/Main"
import ServiceView from "../views/ServiceView"
import ProfileView from "../views/profile/ProfileView"
import CarListView from "../views/cars/CarList"
import CarAddView from "../views/cars/CarAddView"
import PolicyView from "../views/policy/PolicyView"
import AddressView from "../views/address/AddressView"
import HistoryView from "../views/history/HistoryView"
import RegisterPartnerShipView from "../views/partnerships/RegisterPartnerShipView"
import PaymentView from "../views/PaymentView"
import NoticeView from "../views/systems/NoticeView"
import FaqView from "../views/systems/FaqView"
import RegisterHomeView from "../views/partnerships/RegisterHomeView"
import RegisterCompanyView from "../views/partnerships/RegisterCompanyView"
import WashRequestView from "../views/reservation/WashRequestView"
import CleanRequestView from "../views/reservation/CleanRequestView"
import ReservationPayment from "../views/reservation/ReservationPayment"
import HistoryDetailView from "../views/history/HistoryDetailView"
import PlaceAddView from "../views/address/PlaceAddView"
import SearchView from "../views/SearchView"

const routes = [
    {
        path: "/", element: <LoginView />,
    },
    {
        path: "/app",
        children: [
            {path: "", element: <MainView />},
            {path: "washing", element: <WashRequestView />},
            {path: "cleaning", element: <CleanRequestView />},
            {path: "search", element: <SearchView />},
            {path: "payments", element: <ReservationPayment />},
        ],
    },
    {
        path: "/services",
        children: [
            {path: "", element: <ServiceView />},
        ],
    },
    {
        path: "/my",
        children: [
            {path: "", element: <ProfileView />},
            {path: "cars", element: <CarListView />},
            {path: "addresses", element: <AddressView />},
            {
                path: "histories", children: [
                    {path: "", element: <HistoryView />},
                    {path: ":id", element: <HistoryDetailView />},
                ],
            },
            {path: "payments", element: <PaymentView />},
            /*{path: "request-partnership", element: <RegisterPartnerShipView/>},*/
            {path: "policy", element: <PolicyView />},
            {path: "opensource", element: <ProfileView />},
            {path: "faq", element: <FaqView />},
            {path: "notices", element: <NoticeView />},
        ],
    },
    {
        path: "/places",
        children: [
            {path: "", element: <AddressView />},
            {path: "add", element: <PlaceAddView />},
        ],
    },
    {
        path: "/cars",
        children: [
            {path: "add", element: <CarAddView />},
        ],
    },
    {
        path: "/partnerships",
        children: [
            {path: "", element: <RegisterPartnerShipView />},
            {path: "home", element: <RegisterHomeView />},
            {path: "office", element: <RegisterCompanyView />},
        ],
    },
]

export default routes
