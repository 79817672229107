import style from "../../css/module/ReservationStatusBox.module.scss"
import ServiceCenterIcon from "../../resources/icon/service_center.svg"
import NextStepImg from "../../resources/icon/next-step.svg"
import ShoppingIcon from "../../resources/icon/shopping.svg"
import WaitingIcon from "../../resources/icon/waiting.svg"
import DoneIcon from "../../resources/icon/done.svg"

function ReservationStatusBox(props) {

    const {title, message, status, actions} = props

    return (
        <div className={style.RoundBox}>
            <div className={style.StatusInfo}>
                <div className={`${style.Status} ${status === 0 ? style.Active : ""}`}>
                    <div className={style.Circle}>
                        <img src={ServiceCenterIcon} alt={""} />
                    </div>
                    예약진행중
                </div>
                <div className={style.LinkLine}>
                    <img src={NextStepImg} style={{display: status === 0 ? "block" : "none"}} alt={""}/>
                </div>
                <div className={`${style.Status} ${status === 1 ? style.Active : ""}`}>
                    <div className={style.Circle}>
                        <img src={ShoppingIcon} alt={""} />
                    </div>
                    결제 대기
                </div>
                <div className={style.LinkLine}>
                    <img src={NextStepImg} style={{display: status === 1 ? "block" : "none"}} alt={""} />
                </div>
                <div className={`${style.Status} ${status === 2 ? style.Active : ""}`}>
                    <div className={style.Circle}>
                        <img src={WaitingIcon} alt={""} />
                    </div>
                    세차 중
                </div>
                <div className={style.LinkLine}>
                    <img src={NextStepImg} style={{display: status === 2 ? "block" : "none"}} alt={""} />
                </div>
                <div className={`${style.Status} ${status === 3 ? style.Active : ""}`}>
                    <div className={style.Circle}>
                        <img src={DoneIcon} alt={""} />
                    </div>
                    세차 완료
                </div>
            </div>
            <div className={style.Title}>{title}</div>
            <div className={style.Message}>{message}</div>
            <div className={style.Actions} style={{display: status === 1 ? "block" : "none"}}>
                {actions}
            </div>
        </div>
    )
}

export default ReservationStatusBox