import BottomBar from "../../components/BottomBar"
import {useNavigate} from "react-router-dom"

import style from "../../css/module/ProfileView.module.scss"

import {useSessionStorage} from "../../modules/hooks/useSessionStorage"

import Notifications from "../../resources/icon/notifications.svg"
import NavigationBar from "../../components/NavigationBar"

function ProfileView() {

    const navigate = useNavigate()

    const [user, setUser, removeUser] = useSessionStorage("USER")

    const tabBarCallback = (mode) => {
        if (mode === "services") {
            navigate("/services")
            return
        }

        if (mode === "my") {
            return
        }

        if (mode === "washing") {
            navigate("/app?mode=washing")
            return
        }

        if (mode === "cleaning") {
            navigate("/app?mode=cleaning")
        }
    }

    const click = (uri) => {
        navigate("/my/" + uri)
    }

    return (
        <div className={style.WhiteBg}>
            <NavigationBar title={"내 정보"} rightItems={[
                <img key={Math.random()} src={Notifications} alt={"알림 버튼"} />,
            ]} />
            <div className={style.Settings}>
                <div className={style.Profile}>
                    <div className={style.ProfileImage}>
                        <img src={user.profile} alt={""} />
                    </div>
                    <div className={style.ProfileInfo}>
                        <div className={style.Fullname}>{user.name}</div>
                        <div className={style.CellNumber}>{user.cellNumber}</div>
                    </div>
                </div>
                <div className={style.SettingGroup}>
                    <div className={style.SettingItem} onClick={() => {
                        click("cars")
                    }}>차량 관리
                    </div>
                    <div className={style.SettingItem} onClick={() => {
                        click("addresses")
                    }}>주소 관리
                    </div>
                    <div className={style.SettingItem} onClick={() => {
                        click("histories")
                    }}>이용내역
                    </div>
                    <div className={style.SettingItem} onClick={() => {
                        click("payments")
                    }}>결제수단관리
                    </div>
                    <div className={style.SettingItem} onClick={() => {
                        navigate("/partnerships")
                    }}>제휴사 등록
                    </div>
                </div>
                <div className={style.GroupTitle}>
                    서비스이용약관
                </div>
                <div className={style.SettingGroup}>
                    <div className={style.SettingItem} onClick={() => {
                        click("policy?type=privacy")
                    }}>개인정보취급방침
                    </div>
                    <div className={style.SettingItem} onClick={() => {
                        click("policy?type=service")
                    }}>이용약관
                    </div>
                    <div className={style.SettingItem} onClick={() => {
                        // click("opensource")
                    }}>오픈 소스 라이브러리
                    </div>
                    <div className={style.SettingItem}>
                        버전 <span className={style.Accessary}>1.0(alpha)</span>
                    </div>
                    <div className={style.SettingItem} onClick={() => {
                        click("faq")
                    }}>FAQ
                    </div>
                    <div className={style.SettingItem} onClick={() => {
                        click("notices")
                    }}>공지사항
                    </div>
                </div>
                <div className={style.GroupTitle}></div>
                <div className={style.SettingGroup}>
                    <div className={style.SettingItem} onClick={() => {
                        removeUser(null)
                        navigate("/")
                    }}>로그아웃
                    </div>
                    <div className={style.SettingItem} onClick={() => {
                        removeUser(null)
                        navigate("/")
                    }}>회원탈퇴
                    </div>
                </div>
            </div>
            <BottomBar appMode={"my"} onClick={tabBarCallback} />
        </div>
    )

}

export default ProfileView