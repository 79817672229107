import BackButton from "./buttons/BackButton";
import style from "../css/module/NavigationBar.module.scss";


function NavigationBar(props) {

    const {title, backButton, rightItems} = props

    return (
        <div className={style.AppBar}>
            <div className={style.leftItems}>
                {backButton && <BackButton/>}
            </div>
            <div className={"title"}>{title}</div>
            <div className={style.rightItems}>
                {rightItems}
            </div>
        </div>
    )
}

export default NavigationBar