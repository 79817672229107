import React from "react"

import style from "../css/module/BottomBar.module.scss"

import CarWashIcon from "../resources/icon/car-wash.svg"
import CarWashSelectedIcon from "../resources/icon/car-wash-selected.svg"
import CleaningIcon from "../resources/icon/cleaning.svg"
import CleaningSelectedIcon from "../resources/icon/cleaning-selected.svg"
import AppsIcon from "../resources/icon/apps.svg"
import AppsSelectedIcon from "../resources/icon/apps-selected.svg"
import MyIcon from "../resources/icon/my.svg"
import MySelectedIcon from "../resources/icon/my-selected.svg"
import classnames from "classnames"

function TabBarButton({title, state, normal, focus, click, type}: {
    title: string, state: boolean, normal: string, focus: string, click: (v: string) => void, type: string
}) {
    const icon = () => {
        return state ? focus : normal
    }

    return (
        <>
            <div className={classnames(`${style.BottomButton}`, {[`${style.selected}`]: state})} onClick={(e) => {
                e.preventDefault()
                click(type)
            }}>
                <img src={icon()} alt={"icon"} /> {title}
            </div>
        </>
    )
}

export default function BottomBar({onClick, appMode}: {onClick: (mode: string) => void, appMode: string}) {

    const state = (type: string) => {
        return appMode === type
    }

    const toggle = (mode: string) => {
        console.trace(`toggle: ${mode}`)
        onClick(mode)
    }

    return (
        <div className={style.BottomLayout}>
            <div className={style.BottomBar}>
                <>
                    <TabBarButton title={"세차"} type={"washing"} state={state("washing")} normal={CarWashIcon} focus={CarWashSelectedIcon} click={toggle} />
                </>
                <>
                    <TabBarButton title={"청소"} type={"cleaning"} state={state("cleaning")} normal={CleaningIcon} focus={CleaningSelectedIcon} click={toggle} />
                </>
                <>
                    <TabBarButton title={"전체"} type={"services"} state={state("services")} normal={AppsIcon} focus={AppsSelectedIcon} click={toggle} />
                </>
                <>
                    <TabBarButton title={"my"} type={"my"} state={state("my")} normal={MyIcon} focus={MySelectedIcon} click={toggle} />
                </>
            </div>
        </div>
    )
}
