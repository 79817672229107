import BackButton from "../../components/buttons/BackButton";

import "../../css/RegisterPartnerShipView.module.scss";
import {useNavigate} from "react-router-dom";

import formStyle from "../../css/Form.module.scss";
import BarButton from "../../components/buttons/BarButton";
import Dropdown from "../../components/Dropdown";
import {useEffect, useState} from "react";
import {PartnerFetch} from "../../model/PartnerFetch";
import useLocalStorage from "../../modules/useLocalStorage";

function RegisterCompanyView() {

    const navigate = useNavigate();
    const [selected, setSelected] = useState("")
    const [detailAddress, setDetailAddress] = useState("")
    const [partners, setPartners] = useState([])
    const {companyPartners} = PartnerFetch()

    const [companyPartner, setCompanyPartner] = useLocalStorage("companyPartner")

    const validate = () => {

        if (selected === "" || detailAddress === "") {
            alert("필수 사항을 모두 입력해주세요.")
            return
        }

        setCompanyPartner({
            type: "COMPANY",
            id: partners[selected].id,
            title: partners[selected].title,
            other: {
                detailAddress: detailAddress
            }
        })

        navigate(-1)
    }

    useEffect(() => {
        companyPartners().then(data => {
            setPartners(data)
        })
    })

    return (
        <div className={"WhiteBg"}>
            <div className={"AppBar"}>
                <div className={"leftItems"}>
                    <BackButton/>
                </div>
                <div className={"title"}>제휴 회사 등록</div>
                <div className={"rightItems"}></div>
            </div>
            <div className={formStyle.FormWrapper}>
                <div className={formStyle.Form}>
                    <Dropdown placeholder={"회사 선택"} optionList={partners} label={"회사리스트"} disabled={false} state={selected} setState={setSelected} size={"large"}/>
                    <div className={formStyle.InputGroup}>
                        <label>회사 이메일</label>
                        <input type={"email"} placeholder={"예) abc@example.com"} onChange={(e) => {
                            setDetailAddress(e.target.value)
                        }}/>
                    </div>
                </div>
            </div>
            <div className={"FixToBottom"}>
                <BarButton text={"확인"} backgroundColor={"#375126"} textColor={"#FFF"} click={() => {
                    validate()
                }}/>
            </div>
        </div>
    )
}

export default RegisterCompanyView
