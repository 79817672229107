import BarButton from "./BarButton";

import style from "./BottomActionButton.module.scss"


function BottomActionButton(props) {

    const {title, textColor, backgroundColor, click} = props

    return (
        <>
            <div className={style.FixToBottom}>
                <BarButton text={title} backgroundColor={backgroundColor} textColor={textColor} click={() => {
                    click()
                }}/>
            </div>
        </>
    )
}


export default BottomActionButton