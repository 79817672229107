import style from "../css/module/CarColorChip.module.scss"

function CarColorChip(props) {

    const {colorCode, size = 20, isCircle = false} = props

    const getColor = () => {
        switch (colorCode) {
            case 1:
                return `${style.ColorChip} ${style.White}`
            case 2:
                return `${style.ColorChip} ${style.Silver}`
            case 3:
                return `${style.ColorChip} ${style.Black}`
            case 4:
                return `${style.ColorChip} ${style.SilverGray}`
            case 5:
                return `${style.ColorChip} ${style.PearlGray}`
            case 6:
                return `${style.ColorChip} ${style.Red}`
            case 7:
                return `${style.ColorChip} ${style.Orange}`
            case 8:
                return `${style.ColorChip} ${style.Yellow}`
            case 9:
                return `${style.ColorChip} ${style.Green}`
            case 10:
                return `${style.ColorChip} ${style.LightSilver}`
            case 11:
                return `${style.ColorChip} ${style.YellowishGreen}`
            case 12:
                return `${style.ColorChip} ${style.Sapphire}`
            case 13:
                return `${style.ColorChip} ${style.LightGreen}`
            case 14:
                return `${style.ColorChip} ${style.Sky}`
            case 15:
                return `${style.ColorChip} ${style.Galaxy}`
            case 16:
                return `${style.ColorChip} ${style.Gray}`
            case 17:
                return `${style.ColorChip} ${style.Reeds}`
            case 18:
                return `${style.ColorChip} ${style.LightGold}`
            case 19:
                return `${style.ColorChip} ${style.Brown}`
            case 20:
                return `${style.ColorChip} ${style.Gold}`
            case 21:
                return `${style.ColorChip} ${style.Blue}`
            case 22:
                return `${style.ColorChip} ${style.Plum}`
            case 23:
                return `${style.ColorChip} ${style.Purple}`
            case 24:
                return `${style.ColorChip} ${style.Pink}`
            default:
                return `${style.ColorChip} ${style.Unknown}`
        }
    }

    return (
        <span className={getColor()} style={{minWidth: size, maxWidth: size, minHeight: size, maxHeight: size, borderRadius: isCircle ? size / 2 : 0}}></span>
    )
}

export default CarColorChip