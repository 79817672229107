import style from "../css/module/RadioButton.module.scss"

function RadioButton(props) {
    return (
        <div className={style.RadioButton}>
            <input id={props.id} onChange={props.changed} value={props.value} type="radio" checked={props.isSelected}/>
            <label htmlFor={props.id}>{props.label}</label>
        </div>
    );
}

export default RadioButton