import style from "./HistoryView.module.scss"
import NavigationBar from "../../components/NavigationBar"
import {useEffect, useState} from "react"
import moment from "moment"
import {thousandSeparator} from "../../utils/NumberUtils"
import {useNavigate} from "react-router-dom"
import {HistoryFetch} from "../../model/HistoryFetch"
import {History} from "../../model/types/History"

const HistoryView = () => {

    const navigate = useNavigate()

    const {histories, history} = HistoryFetch()

    const [filter, setFilter] = useState()

    const [contents, setContents] = useState<History[]>([])

    const validate = () => {
        alert("필수 사항을 모두 입력해주세요.")
    }

    useEffect(() => {
        setTimeout(() => {
            histories().then(data => {
                if (!(data === null || data === undefined)) {
                    setContents(data)
                }
            })
        }, 50)
    }, [setContents, navigate, histories])

    return (
        <div className={"WhiteBg"}>
            <NavigationBar title={"이용 내역"} backButton={true} />
            <div className={style.Filter}>
                <span className={`${style.Chip} ${style.Select}`}>전체</span>
                <span className={style.Chip}>세차</span>
                <span className={style.Chip}>청소</span>
            </div>
            <div className={style.Histories}>
                {contents && contents.map((content) => (
                    <div key={content.id} className={style.History} onClick={() => {
                        navigate(`/my/histories/${content.id}`)
                    }}>
                        <div className={style.Status}>{
                            content.type === "WASH" ? "세차완료" : (content.type === "CLEAN" ? "청소완료" : "N/A")
                        }</div>
                        <div className={style.Meta}>
                            <div>{moment(content.date).format("YYYY-MM-DD a hh:mm")}</div>
                            <div className={style.Price}>{thousandSeparator(content.price)}원</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default HistoryView