import petSitter from "../resources/demo/water-dispenser.svg"
import petFood from "../resources/demo/pet-food.svg"
import petHotel from "../resources/demo/pet-hotel.svg"
import petStuff from "../resources/demo/pet-stuff.svg"
import healthFood from "../resources/demo/health-food.svg"
import bentto from "../resources/demo/bentto.svg"

import recommend from "../resources/demo/service-recommend-card.png"
import {Service} from "./types/Service"

export const ServiceFetch = () => {

    const services = async () => {
        return new Promise((resolve, reject) => {
            resolve([
                {
                    id: 1,
                    type: "SERVICE",
                    title: "펫시터",
                    thumbnail: petSitter,
                    clickUrl: "",
                } as Service,
                {
                    id: 2,
                    type: "SERVICE",
                    title: "반려용품",
                    thumbnail: petStuff,
                    clickUrl: "",
                } as Service,
                {
                    id: 3,
                    type: "SERVICE",
                    title: "반려간식",
                    thumbnail: petFood,
                    clickUrl: "",
                } as Service,
                {
                    id: 4,
                    type: "SERVICE",
                    title: "펫호텔",
                    thumbnail: petHotel,
                    clickUrl: "",
                } as Service,
                {
                    id: 5,
                    type: "SERVICE",
                    title: "건강식품",
                    thumbnail: healthFood,
                    clickUrl: "",
                } as Service,
                {
                    id: 6,
                    type: "SERVICE",
                    title: "도시락",
                    thumbnail: bentto,
                    clickUrl: "",
                } as Service,
            ])
        })
    }

    const recommendServices = async () => {
        return new Promise((resolve) => {
            resolve([
                {
                    id: 6,
                    type: "RECOMMEND_SERVICE",
                    title: "도시락",
                    thumbnail: recommend,
                    clickUrl: "https://naver.com",
                } as Service,
            ])
        })
    }

    return {services, recommendServices}
}