import style from "./HistoryDetailView.module.scss"
import {useEffect, useState} from "react"
import moment from "moment"
import {thousandSeparator} from "../../utils/NumberUtils"
import {Map, useKakaoLoader} from "react-kakao-maps-sdk"
import {useNavigate} from "react-router-dom"
import {CarFetch} from "../../model/CarFetch"

import mapMarker from "../../resources/icon/mapMarker.svg"
import {HistoryFetch} from "../../model/HistoryFetch"
import {History} from "../../model/types"

const CleanHistoryDetailView = (data: {contentId: number}) => {

    const navigate = useNavigate()

    const [content, setContent] = useState<History>()
    const {cars: carFetcher, car: getCar} = CarFetch()

    const {history} = HistoryFetch()

    const [filter, setFilter] = useState()

    const [state, setState] = useState<{level: number, center: {lat: number, lng: number}}>()

    const [price] = useState(70000)

    const [loading, error] = useKakaoLoader({
        appkey: "9ef65d72b96c6881edf8066e09089d38", // 발급 받은 APPKEY
    })

    const {contentId} = data

    useEffect(() => {
        setTimeout(() => {
            if (!contentId) {
                alert("잘못된 경로 입니다.")
                navigate("/app")
            }
            else {
                history(contentId).then(data => {
                    if (!(data === null || data === undefined)) {
                        setContent(data)
                    }
                })
            }
        }, 50)
    }, [navigate, getCar, history])

    const render = (content: History) => {
        return (
            <>
                <div className={style.Meta}>
                    <div className={style.MetaTitle}>
                        청소
                    </div>
                    <div className={style.Time}>{moment(content.date, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD a hh:mm")}</div>
                    <Map id="map" className={style.Map} center={{lat: content.address.lat, lng: content.address.lng}} level={2} onCenterChanged={(map) => setState({
                        level: map.getLevel(),
                        center: {
                            lat: map.getCenter().getLat(),
                            lng: map.getCenter().getLng(),
                        },
                    })} />
                </div>
                <div className={style.Meta}>
                    <div className={style.MetaTitle}>
                        상세내역
                    </div>
                    <div className={style.Address}>
                        <div className={style.AddressTitle}>
                            <img src={mapMarker} alt={""} />
                            <span>우리집</span>
                        </div>
                        <div className={style.AddressDetail}>
                            <div className={style.Parcel}>{content.address.parcel}</div>
                            <div className={style.Road}><span className={style.Label}>도로명</span>{content.address.road}</div>
                        </div>
                    </div>
                    <div className={style.CarProduct}>
                        <div className={style.CarProductInfo}>
                            이용 상품
                            <div>거품+실내세차</div>
                        </div>
                        <div className={style.CarProductInfo}>
                            결제 금액
                            <div>{thousandSeparator(price)}원</div>
                        </div>
                        <div className={style.CarProductInfo}>
                            결제 수단
                            <div>현장결제</div>
                        </div>
                        <div className={style.CarProductInfo}>
                            할인 옵션
                            <div>청담 자이 입주민 <span className={style.point}>-7%</span></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {content && render(content)}
        </>
    )
}

export default CleanHistoryDetailView