import style from "../css/module/DateLabel.module.scss"
import {Dayjs} from "dayjs"
import React from "react"

type DateLabelProps = {
    date: Dayjs;
    format: string;
}

const DateLabel = ({date, format}: DateLabelProps) => (
    <>
        <span className={style.DateLabel}>{date.format(format)}</span>
    </>
)

DateLabel.defaultProps = {
    format: "YYYY년 MM월 DD일 dddd",
}

export default DateLabel