import '../../css/App.scss';

import style from "../../css/module/Login.module.scss"

import logo from "../../resources/workswith.svg";
import BarButton from "../../components/buttons/BarButton";
import {useNavigate} from "react-router-dom";

import googleLogo from "../../resources/icon-google.svg";
import kakaoLogo from "../../resources/icon-kakao.svg";
import naverLogo from "../../resources/icon-naver.svg";
import appleLogo from "../../resources/icon-apple.svg";

import profile from "../../resources/demo/profile.jpg";
import Keys from "../../keys"
import {useSessionStorage} from "../../modules/hooks/useSessionStorage"
import {UserProfile} from "../../model/types/UserProfile"

const Login = () => {

    const navigate = useNavigate()

    const [, setUser] = useSessionStorage<UserProfile>(Keys.User)

    const jumpToDemo = () => {

        setUser({
            id: 1,
            username: "test-user",
            name: "홍길염",
            cellNumber: "010-3190-0011",
            profile: profile
        })

        navigate("/app?mode=washing")
    }

    return (<div className={style.LoginView}>
        <div className={style.LogoWrapper}>
            <img src={logo} alt={""}/>
        </div>
        <div className={style.ButtonWrapper}>
            <BarButton icon={googleLogo} text={"구글로 시작하기"} backgroundColor={"#fff"} textColor={"#000"} click={() => {
                jumpToDemo()
            }}/>

            <BarButton icon={kakaoLogo} text={"카카오로 시작하기"} backgroundColor={"#FAE64C"} textColor={"#000"} click={() => {
                jumpToDemo()
            }}/>

            <BarButton icon={naverLogo} text={"네이버로 시작하기"} backgroundColor={"#04D966"} textColor={"#FFF"} click={() => {
                jumpToDemo()
            }}/>

            <BarButton icon={appleLogo} text={"Apple로 시작하기"} backgroundColor={"#000000"} textColor={"#fff"} click={() => {
                jumpToDemo()
            }}/>
        </div>
    </div>)
}

export default Login
