import style from "../css/module/ServiceView.module.scss";
import BottomBar from "../components/BottomBar";
import {useNavigate} from "react-router-dom";
import {ServiceFetch} from "../model/ServiceFetch";
import {useEffect, useState} from "react";
import Search from "../resources/icon/search.svg";
import {useSessionStorage} from "../modules/hooks/useSessionStorage";

function ServiceView() {

    const navigate = useNavigate();
    const [services, setServices] = useState([])
    const [recommendServices, setRecommendServices] = useState([])

    const {services: serviceFetcher, recommendServices: recommendFetcher} = ServiceFetch();

    const [user] = useSessionStorage("USER")

    const tabBarCallback = (mode) => {
        if (mode === "services") {
            return
        }

        if (mode === "my") {
            navigate("/my")
            return
        }

        if (mode === "washing") {
            navigate("/app?mode=washing")
            return
        }

        if (mode === "cleaning") {
            navigate("/app?mode=cleaning")
        }
    }


    useEffect(() => {
        setTimeout(() => {
            serviceFetcher().then(data => {
                setServices(data)
            })
        }, 50)
    })

    useEffect(() => {
        setTimeout(() => {
            recommendFetcher().then(data => {
                setRecommendServices(data)
            })
        }, 50)
    })

    return (
        <div className={style.WhiteBg}>
            <div className={style.AllServices}>
                <div className={style.Header}>
                    <div className={style.SearchBox}>
                        <img src={Search} alt={"search icon"}/>
                        <input placeholder={"어떤서비스를 찾으시나요?"}/>
                    </div>
                </div>
                <div className={style.Services}>
                    <div className={style.ServiceGrid}>
                        {services && services.map(data => {
                            return (
                                <div className={style.ServiceCard} data-id={data.id} key={data.id}>
                                    <a href={data.clickUrl} target={"_blank"} rel="noopener noreferrer">
                                        <img src={data.thumbnail} alt={data.title}/>
                                        {data?.title}
                                    </a>
                                </div>
                            )
                        })}
                    </div>
                    <hr/>
                    <div className={style.ServiceRecommends}>
                        <div className={style.title}>{user.name}님이 좋아하실만한</div>
                        <div className={style.RecommendCarousel}>
                            {recommendServices && recommendServices.map(data => {
                                return (
                                    <div className={style.RecommendItem} key={data.id}>
                                        <div className={"ResponsiveImg"}>
                                            <a href={data.clickUrl} target={"_blank"} rel="noopener noreferrer">
                                                <img src={data.thumbnail} alt={data.title}/>
                                            </a>
                                        </div>
                                    </div>
                                )
                            })} {recommendServices && <span className={style.indicator}>1/{recommendServices.length} 모두 보기</span>}
                        </div>
                    </div>
                </div>
            </div>
            <BottomBar appMode={"services"} onClick={tabBarCallback}/>
        </div>
    )
}

export default ServiceView