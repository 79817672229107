import {createRoot} from "react-dom/client"
import "./index.css"
import Root from "./Root"
import {BrowserRouter} from "react-router-dom"
import reportWebVitals from "./reportWebVitals"
import React from "react"

const container = document.getElementById("root")
const root = createRoot(container!)

root.render(
    // <React.StrictMode>  </React.StrictMode>,
    <BrowserRouter> <Root /> </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
