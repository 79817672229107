import BackButton from "../../components/buttons/BackButton";

import formStyle from "../../css/Form.module.scss";

import {useNavigate} from "react-router-dom";
import BarButton from "../../components/buttons/BarButton";
import Dropdown from "../../components/Dropdown";
import {useEffect, useState} from "react";
import {PartnerFetch} from "../../model/PartnerFetch";
import useLocalStorage from "../../modules/useLocalStorage";

function RegisterHomeView() {

    const navigate = useNavigate();
    const [selected, setSelected] = useState("")
    const [detailAddress, setDetailAddress] = useState("")
    const [partners, setPartners] = useState([])
    const {housePartners} = PartnerFetch()

    const [homePartner, setHomePartner] = useLocalStorage("homePartner")

    const validate = () => {

        if (selected === "" || detailAddress === "") {
            alert("필수 사항을 모두 입력해주세요.")
            return
        }

        setHomePartner({
            type: "HOME",
            id: partners[selected].id,
            title: partners[selected].title,
            other: {
                detailAddress: detailAddress
            },
            status: "PENDING_APPROVE",
            contact: "010-1111-2222"
        })

        navigate(-1)
    }

    useEffect(() => {
        housePartners().then(data => {
            setPartners(data)
        })
    })

    return (
        <div className={"WhiteBg"}>
            <div className={"AppBar"}>
                <div className={"leftItems"}>
                    <BackButton/>
                </div>
                <div className={"title"}>제휴 아파트 등록</div>
                <div className={"rightItems"}></div>
            </div>
            <div className={formStyle.FormWrapper}>
                <div className={formStyle.Form}>
                    <Dropdown placeholder={"단지 선택"} optionList={partners} label={"단지"} disabled={false} state={selected} setState={setSelected} size={"large"}/>
                    <div className={formStyle.InputGroup}>
                        <label>상세주소 입력</label>
                        <input placeholder={"예) 101동 101호"} onChange={(e) => {
                            setDetailAddress(e.target.value)
                        }}/>
                    </div>
                </div>
            </div>
            <div className={"FixToBottom"}>
                <BarButton text={"확인"} backgroundColor={"#375126"} textColor={"#FFF"} click={() => {
                    validate()
                }}/>
            </div>
        </div>
    )
}

export default RegisterHomeView