import React from "react";

import style from "../css/module/BottomDrawer.module.scss"
import {Link, useNavigate} from "react-router-dom"

function BottomDrawer({cars}) {

    const navigate = useNavigate();

    const render = (cars) => {
        if (Array.isArray(cars) && cars.length > 0) {
            return (
                <div className={style.cars}>
                    <div className={style.left}>
                        <div className={style.title}>
                            차량 정보
                        </div>

                        <div className={`${style.title} mt-3`}>
                            {cars[0].plateNumber}
                        </div>
                        <div className={style.subtitle}>
                            {cars[0].model}
                        </div>
                    </div>
                    <div className={style.right}>
                        <img src={cars[0].image} alt={""}/>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className={style.cars}>
                    <div className={style.left}>
                        <div className={style.title}>
                            등록된 차량이 없습니다.
                        </div>
                        <div className={style.subtitle}>
                            차량을 등록해주세요.
                        </div>
                    </div>
                    <div className={style.right}>
                        <Link to={"#"} className={style.button} onClick={(e) => {
                            e.preventDefault()
                            navigate("/cars/add")
                        }}>차량 등록하기
                        </Link>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className={style.BottomDrawer}>
            <div className={style.DrawerHandle}>
                <span className={style.bar}></span>
            </div>
            {render(cars)}
        </div>
    )
}

export default BottomDrawer