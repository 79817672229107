import style from "./Cars.module.scss";

import AddIcon from "../../resources/icon/add.svg";
import {CarFetch} from "../../model/CarFetch";
import {useCallback, useEffect, useState} from "react"

import CarColorChip from "../../components/CarColorChip";
import {useNavigate} from "react-router-dom";
import NavigationBar from "../../components/NavigationBar";

function CarListView() {

    const navigate = useNavigate();
    const [cars, setCars] = useState([]);
    const {cars: carFetcher, deleteCar} = CarFetch();

    const reloadPage = useCallback(() => {
        carFetcher(0).then(data => {
            setCars(data)
        })
    }, [carFetcher, cars, setCars])

    useEffect(() => {
        setTimeout(() => {
            carFetcher().then(data => {
                setCars(data)
            })
        }, 50)
    }, [carFetcher, cars, setCars])

    return (
        <div className={"WhiteBg"}>
            <NavigationBar title={"차량 관리"} backButton={true}/>
            <div className={style.Cars}>
                {cars.length === 0 && <div className={style.NoCar}>No Car</div>}
                {cars.length !== 0 && cars.map((car) => {
                return (<div className={style.Car} key={car.id}>
                    <img src={car.image} alt={""}/>
                    <div className={style.PlateNumber}>{car.plateNumber}</div>
                    <div className={style.ModelInfo}>
                        <CarColorChip colorCode={car.color} size={20} isCircle={true}/> {car.model}
                    </div>
                    <div className={style.Delete} onClick={() => {
                        deleteCar(car.id).then(response => {
                            console.info(response)
                            if (response) {
                                reloadPage()
                            }
                        })
                    }}>
                        삭제
                    </div>
                </div>)
            })}
                <div className={style.Add} onClick={() => {
                    navigate("/cars/add")
                }}>
                    <img src={AddIcon} alt={""}/>
                    <span>차량 추가 하기</span>
                </div>
            </div>
        </div>
    )
}

export default CarListView
