import BottomBar from "../components/BottomBar"
import style from "../css/module/Main.module.scss"
import "../css/KakaoMap.scss"

import {Map} from "react-kakao-maps-sdk"
import SearchIcon from "../resources/icon/search.svg"
import {useNavigate, useSearchParams} from "react-router-dom"
import React, {useCallback, useEffect, useRef, useState} from "react"

import MarkerIcon from "../resources/icon/map-marker.svg"
import {AddressAPI} from "../model/AddressAPI"
import CarBottomDrawer from "../components/CarBottomDrawer"
import AddressBottomDrawer from "../components/AddressBottomDrawer"
import {Car, Geo, Location, UserProfile} from "../model/types"
import Keys from "../keys"
import {useSessionStorage} from "../modules/hooks/useSessionStorage"
import UserProfileFetch from "../model/UserProfileFetch"

const Main = () => {

    // const [loadding, error] = useKakaoLoader({
    //     appkey: "9ef65d72b96c6881edf8066e09089d38",
    // })

    const [user] = useSessionStorage<UserProfile>(Keys.User)
    const [device] = useSessionStorage<string>(Keys.Device)

    const isAndroidApp = useState<boolean>(device === "android")
    const isIOSApp = useState<boolean>(device === "ios")
    const isLocationTermsAgreed = useState<boolean>(false)

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [curMode, setCurMode] = useState(searchParams.get("mode") ?? "washing")

    const [cars, setCars] = useState<Car[]>([])
    const [locations, setLocations] = useState<Location[]>([])
    const [selected, setSelected] = useState<Car | Location>(null)
    const {getCars, getAddress} = UserProfileFetch()

    const searchRef = useRef<HTMLInputElement>(null)

    const {coordToAddress} = AddressAPI()
    const [geo, setGeo] = useState<Geo | null>({
        lat: 37.5666612,
        lng: 126.9783785,
    })

    const [searchInput] = useState<string>()
    const [isCallingAPI, setCallingAPI] = useState<boolean>(false)

    useEffect(() => {
        setTimeout(() => {
            if (curMode === "washing") {
                getCars(user.id).then(data => {
                    setCars(data)
                    setSelected(data[0])
                })
            }
            if (curMode === "cleaning") {
                getAddress(user.id).then(data => {
                    setLocations(data)
                    setSelected(data[0])
                })
            }
        }, 50)
    }, [curMode])

    const requestReservation = useCallback(() => {
        if (!geo || !searchRef || !searchRef.current) {
            console.info(geo)
            console.info(searchRef)
            console.info(searchRef.current)
            return
        }

        console.trace(`request reservation - ${searchParams.get("mode")}`)
        if (cars.length > 0) {
            if (searchParams.get("mode") === "washing") {
                navigate(`/app/washing?car=${selected.id}&addr=${searchRef.current.value}&lat=${geo.lat}&lng=${geo.lng}`)
            }
            else if (searchParams.get("mode") === "cleaning") {
                navigate(`/app/cleaning?place=${selected.id}&addr=${searchRef.current.value}&lat=${geo.lat}&lng=${geo.lng}`)
            }
        }
        else {
            alert("차량을 등록해주세요")
        }
    }, [searchParams, searchRef, geo, navigate, cars])

    const changeCenter = (map: kakao.maps.Map) => {
        const latitude = map.getCenter().getLat()
        const longitude = map.getCenter().getLng()
        setGeo({lat: latitude, lng: longitude})
    }

    useEffect(() => {
        callAPI()
    }, [geo])

    useEffect(() => {
        // if (isAndroidApp || isIOSApp || !isLocationTermsAgreed) return
        getGeoLocationCoords()
    }, [])

    const getGeoLocationCoords = useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(({coords}) => {
                const latitude = coords.latitude
                const longitude = coords.longitude
                setGeo({lat: latitude, lng: longitude})
            }, (positionError: GeolocationPositionError) => {
                console.info(positionError.message)
            })
        }
        else {
            console.error("geolocation is not supported")
        }
    }, [])

    const handleLocationPermission = useCallback(() => {
        if (isAndroidApp) {
            // Android native 권한 처리 진행
        }
        else if (isIOSApp) {
            // iOS native 권한 처리 진행
        }
        else if (!isLocationTermsAgreed) {
            // device가 웹이고 약관 동의를 하지 않은 경우, 팝업 노출 진행
            console.error("Not agreed")
        }
    }, [])

    const setSearchValue = (ref: string) => {
        if (searchRef.current !== null)
            searchRef.current.value = ref
    }

    const callAPI = useCallback(() => {
        if (geo === null) return
        if (!isCallingAPI) {
            setCallingAPI(true)
            coordToAddress(geo.lat, geo.lng).then((addresses) => {
                setCallingAPI(false)
                let address = addresses[0]
                setSearchValue(address.road_address?.address_name ?? address.address?.address_name)
            })
        }
    }, [geo])

    return (
        <div className={style.WhiteBg}>
            <div className={style.MapWrapper}>
                <>
                    <Map id="map" className={"kakao_map"} center={geo} level={3} onCreate={(map) =>{
                    }} /*onCenterChanged={changeCenter}*/ onDragEnd={(target) => {
                        setGeo({lat: target.getCenter().getLat(), lng: target.getCenter().getLng()})
                        callAPI()
                    }} />
                    <div className={style.Pin}>
                        <div className={style.MapMaker} onClick={requestReservation}>
                            이 위치로 {curMode === "washing" ? "세차" : "청소"} 예약
                        </div>
                        <img src={MarkerIcon} alt={""} />
                    </div>
                </>
            </div>
            <div className={style.SearchBar}>
                <img src={SearchIcon} alt={"search icon"} />
                <input className={style.SearchInput} value={searchInput} ref={searchRef} onFocus={() => {
                    navigate("/app/search")
                }} />
            </div>
            <>
                {curMode === "washing" && (
                    <CarBottomDrawer cars={cars} />
                )}
                {curMode === "cleaning" && (
                    <AddressBottomDrawer cars={[]} />
                )}
            </>
            <>
                <BottomBar appMode={curMode} onClick={(mode: string) => {
                    if (mode === curMode) {
                        return
                    }

                    if (mode === "services") {
                        navigate("/services")
                        return
                    }

                    if (mode === "my") {
                        navigate("/my")
                        return
                    }

                    if (mode === "cleaning") {
                        setCurMode("cleaning")
                        setSelected(locations[0])
                        setSearchParams({"mode": "cleaning"})
                        return
                    }

                    if (mode === "washing") {
                        setCurMode("washing")
                        setSelected(cars[0])
                        setSearchParams({"mode": "washing"})
                    }
                }} />
            </>
        </div>
    )
}

export default Main
