import style from "../../css/module/ReservationView.module.scss"
import moment from "moment"
import "moment/locale/ko"

function AdminMessage(props) {

    const {image, name, text, time} = props

    return (
        <div className={`${style.Message} ${style.AdminMessage}`}>
            <div className={style.Profile}>
                {image && <img src={image} alt={""} />}
            </div>
            <div>
                <div className={style.Name}>{name}</div>
                <div className={style.ContentWrapper}>
                    <div className={style.Content} dangerouslySetInnerHTML={{__html: text}}></div>
                    <div className={style.Time}>{moment(time).format("a hh:mm")}</div>
                </div>
            </div>
        </div>
    )
}

export default AdminMessage