import {Car} from "./types/Car"
import {Location} from "./types/Location"

import profile from "../resources/demo/profile.jpg"
import DemoCar from "../resources/demo/car.png"

const UserProfileFetch = () => {
    const login = async (username: string, password: string) => {
        return Promise.resolve({
            id: 1,
            username: "test-user",
            name: "홍길동",
            cellNumber: "010-3190-0011",
            profile: profile,
        })
    }

    const loginWithToken = async (token: string) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    id: 1,
                    username: "test-user",
                    name: "홍길동",
                    cellNumber: "010-3190-0011",
                    profile: profile,
                })
            }, 200)
        })
    }

    const getAddress = async (userId: number) => {
        return Promise.resolve([] as Location[])
    }

    const getCars = async (userId: number) => {
        return Promise.resolve([{
            id: 1,
            color: 3,
            plateNumber: "123가5678",
            model: "Audi A6",
            image: DemoCar,
        } as Car])
    }

    return {login, loginWithToken, getAddress, getCars}
}

export default UserProfileFetch