import style from "../../css/module/ReservationPayment.module.scss";
import NavigationBar from "../../components/NavigationBar";
import CarColorChip from "../../components/CarColorChip";
import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {CarFetch} from "../../model/CarFetch";
import RadioButton from "../../components/RadioButton";
import BarButton from "../../components/buttons/BarButton";
import {thousandSeparator} from "../../utils/NumberUtils";

function ReservationPayment() {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams()
    const [objectId] = useState(searchParams.get("revn") ?? null)
    const [car, setCar] = useState({})

    const {cars: carFetcher, car: getCar} = CarFetch();

    const [paymentMethod, setPaymentMethod] = useState("NORMAL")
    const [payment, setPayment] = useState("CREDIT")

    const [price] = useState(70000)
    const [discountRate] = useState(0.07)
    const [discountPrice, setDiscountPrice] = useState(0)

    const [totalPrice, setTotalPrice] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            setDiscountPrice(Math.round(price * discountRate))
        })
    }, [price, discountRate])

    useEffect(() => {
        setTimeout(() => {
            setTotalPrice(price - discountPrice)
        })
    }, [price, discountPrice])

    useEffect(() => {
        setTimeout(() => {
            if (!objectId) {
                alert("잘못된 경로 입니다.")
                navigate("/app")
            }
            else {
                getCar(objectId).then(data => {
                    if (!(data === null || data === undefined)) {
                        setCar(data)
                    }
                })
            }
        }, 50)
    }, [objectId, navigate, getCar]);

    const radioChangeHandler = (event) => {
        setPaymentMethod(event.target.value)
    }

    const paymentChangeHandler = (data) => {
        console.info(data)
        setPayment(data)
    }

    return (
        <div className={style.WhiteBg}>
            <NavigationBar title={"주문 상세 내역"} backButton={true}/>
            <div className={style.CarWrapper}>
                <div className={style.SectionTitle}>
                    상품정보
                </div>
                <div className={style.Cars}>
                    <div>
                        <img className={style.Thumbnail} src={car.image} alt={""}/>
                    </div>
                    <div className={style.CarInfo}>
                        <div className={style.PlateNumber}>{car.plateNumber}</div>
                        <div className={style.ModelInfo}>
                            <CarColorChip colorCode={car.color} size={18} isCircle={true}/> {car.model}
                        </div>
                    </div>
                </div>
                <div className={style.CarProduct}>
                    <div className={style.CarProductInfo}>
                        이용 상품
                        <div>거품+실내세차</div>
                    </div>
                    <div className={style.CarProductInfo}>
                        결제 금액
                        <div>{thousandSeparator(price)}원</div>
                    </div>
                    <div className={style.CarProductInfo}>
                        결제 수단
                        <div>현장결제</div>
                    </div>
                    <div className={style.CarProductInfo}>
                        할인 옵션
                        <div>청담 자이 입주민 <span className={style.point}>-7%</span></div>
                    </div>
                </div>
                <div className={style.GreyBar}></div>
                <div className={style.SectionTitle}>
                    결제정보
                </div>
                <div className={style.PaymentMethods}>
                    <div className={style.PaymentMethod}>
                        <>
                            <RadioButton changed={radioChangeHandler} id={1} isSelected={paymentMethod === "NORMAL"} label={"일반 결제"} value={"NORMAL"}/></>
                        <>
                            <RadioButton changed={radioChangeHandler} id={2} isSelected={paymentMethod === "TOSS"} label={"토스 페이"} value={"TOSS"}/></>
                    </div>
                    <div className={style.PaymentDetails}>
                        <div key={"NORMAL"} className={`${style.PaymentDetail} ${paymentMethod === 'NORMAL' ? style.Show : ''}`}>
                            <div className={style.PaymentGrid}>
                                <span className={`${style.Payment} ${payment === 'CREDIT' ? style.Select : ''}`} onClick={() => {
                                    paymentChangeHandler("CREDIT")
                                }}>카드
                                </span>
                                <span className={`${style.Payment} ${payment === 'VIRTUAL_BANK' ? style.Select : ''}`} onClick={() => {
                                    paymentChangeHandler("VIRTUAL_BANK")
                                }}>가상계좌</span>
                                <span className={`${style.Payment} ${payment === 'BANK' ? style.Select : ''}`} onClick={() => {
                                    paymentChangeHandler("BANK")
                                }}>계좌이체</span>
                                <span className={`${style.Payment} ${payment === 'CELLPHONE' ? style.Select : ''}`} onClick={() => {
                                    paymentChangeHandler("CELLPHONE")
                                }}>휴대폰</span>
                                <span className={`${style.Payment} ${payment === 'NAVERPAY' ? style.Select : ''}`} onClick={() => {
                                    paymentChangeHandler("NAVERPAY")
                                }}>네이버페이</span>
                                <span className={`${style.Payment} ${payment === 'KAKAOPAY' ? style.Select : ''}`} onClick={() => {
                                    paymentChangeHandler("KAKAOPAY")
                                }}>카카오페이</span>
                                <span className={`${style.Payment} ${payment === 'PAYCO' ? style.Select : ''}`} onClick={() => {
                                    paymentChangeHandler("PAYCO")
                                }}>페이코</span>
                            </div>
                        </div>
                        <div key={"TOSS"} className={`${style.PaymentDetail} ${paymentMethod === 'TOSS' ? style.Show : ''}`}>
                            TOSS 연동 필요
                        </div>
                    </div>
                </div>
                <div className={style.GreyBar}></div>
                <div className={style.SectionTitle}>
                    환불방법
                </div>
                <div className={style.Refund}>
                    <span>선택하신 결제 방법으로 환불해 드립니다.</span>
                    <ul>
                        <li>
                            문의하신 상담원에게 문의해주시면 빠르게 환불처리가 가능합니다.
                        </li>
                        <li>
                            현금 환불의 경우, 예금정보가 일치해야 환불 처리가 가능합니다. 은행명, 계좌번호, 예금주명을 정확히 기재 부탁드립니다.
                        </li>
                        <li>
                            환불받으신 날짜 기준으로 3~5일(주말 제외) 후 결제대행사에서 직접 고객님의 계좌로 환불 처리됩니다.
                        </li>
                    </ul>
                </div>
                <div className={style.GreyBar}></div>
                <div className={style.SectionTitle}>
                    최종 결제 금액
                </div>
                <div className={style.PaySummary}>
                    <div className={style.Product}>
                        <div className={style.ProductInfo}>
                            상품 금액
                            <div>{thousandSeparator(price)}원</div>
                        </div>
                        <div className={style.ProductInfo}>
                            할인 합계
                            <div><span className={style.point}>청담 자이 입주민 할인 {Math.round(discountRate * 100)}%</span>&nbsp;&nbsp;<span className={style.Discount}>-{thousandSeparator(discountPrice)}원</span></div>
                        </div>
                        <div className={style.ProductInfo}>
                            결제 수수료
                            <div>0원</div>
                        </div>
                        <div className={`${style.ProductInfo} ${style.Total}`}>
                            결제 금액
                            <div>{thousandSeparator(totalPrice)}원</div>
                        </div>
                    </div>

                    <BarButton className={style.PayButton} text={`${thousandSeparator(totalPrice)}원 결제하기`} click={() => {
                        navigate("/my/histories", {replace: true})
                    }} />
                </div>
            </div>
        </div>
    )
}

export default ReservationPayment